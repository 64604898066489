import api from "@/api/index";

export default {
  create(credentials) {
    return api().post("generateImages/create", credentials);
  },
  genList(credentials) {
    return api().post("generateImages/genList", credentials);
  },
  genList_image_to_image(credentials) {
    return api().post("generateImages/genList_image_to_image", credentials);
  },
};
