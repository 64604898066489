<template>
  <div
    class="bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 mt-20 bg-opacity-0"
  >
    <div
      class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl text-gray-200 pt-24"
    >
      <div class="sm:max-w-xl md:max-w-full lg:max-w-screen-2xl">
        <div class="grid grid-cols-1 gap-2 lg:grid-cols-3">
          <div class="text-center">
            <h3 class="text-xl text-green-400">About Us</h3>
            <h3 class="mt-5 text-sm">
              A Web3 marketplace for AI-generated content
            </h3>

            <div class="flex flex-col items-center p-5 mt-3">
              <div class="flex">
                <a
                  class="m-2 cursor-pointer"
                  href="https://twitter.com/PromptDue"
                  target="_blank"
                >
                  <i class="fab fa-twitter" style="font-size: 20px"></i>
                </a>

                <a
                  class="m-2 cursor-pointer"
                  href="https://www.reddit.com/user/PromptDueCommunity/"
                  target="_blank"
                >
                  <i class="fab fa-reddit" style="font-size: 20px"></i>
                </a>

                <a
                  class="m-2 cursor-pointer"
                  href="https://www.youtube.com/@promptdue/featured"
                  target="_blank"
                >
                  <i class="fab fa-youtube" style="font-size: 20px"></i>
                </a>

                <a
                  class="m-2 cursor-pointer"
                  href="https://www.instagram.com/promptdue/"
                  target="_blank"
                >
                  <i class="fab fa-instagram" style="font-size: 20px"></i>
                </a>

                <a
                  class="m-2 cursor-pointer"
                  href="https://www.facebook.com/profile.php?id=100090372024021"
                  target="_blank"
                >
                  <i class="fab fa-facebook" style="font-size: 20px"></i>
                </a>
              </div>
            </div>
          </div>

          <div class="flex justify-center items-center">
            <div class="text-center">
              <h3 class="text-xl text-green-400">Contact Us</h3>
              <h3 class="mt-5 text-sm">admin@promptdue.com</h3>
            </div>
          </div>

          <div class="flex justify-center items-center">
            <div class="flex flex-col items-center mt-5">
              <div class="flex text-lg text-blue-200">
                <a href="/search" class="ml-2 hover:underline">
                  Search Prompt
                </a>
                <a href="/models" class="ml-5 hover:underline"> Models </a>
                <a
                  href="https://promptdue.gitbook.io/promptdue/"
                  target="_blank"
                  class="ml-5 hover:underline"
                >
                  Docs
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-gray-900 text-gray-200">
      <div
        class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl mt-10 pt-10 pb-10 bg-gray-900"
      >
        <div class="sm:max-w-xl md:max-w-full lg:max-w-screen-2xl">
          <div class="grid grid-cols-1 gap-2 lg:grid-cols-2">
            <div class="flex justify-center text-xs">
              Copyright 2023 © Promptdue.
            </div>

            <div class="mt-5 lg:mt-0">
              <div class="flex justify-center text-sm">
                <a href="/faq" class="m-3 hover:underline"> FAQ </a>
                <a href="/privacy" class="m-3 hover:underline">
                  Privacy Policy
                </a>
                <a href="/TermOfService" class="m-3 hover:underline">
                  Term of Service
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
