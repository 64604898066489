<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div
        class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10 p-4 pt-5 pb-5"
      >
        <div class="text-gray-200">
          <a
            v-on:click="$router.go(-1)"
            class="flex items-center space-x-2 text-center cursor-pointer"
            ><i class="fad fa-arrow-left mr-1"></i><span>Go Back</span></a
          >
        </div>
      </div>

      <div class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg">
        <div class="sm:max-w-xl md:max-w-full lg:max-w-screen-2xl">
          <div
            class="px-4 sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-10 lg:px-8 lg:py-10 -mt-10"
          >
            <!-- <div class="h-screen inline-block hidden lg:block">
              <div class="grid grid-cols-2 gap-1 lg:grid-cols-2">
                <div>
                  <img
                    src="https://cdn.shopify.com/s/files/1/0568/5942/7015/products/MK043ZM_A_1.png?v=1636113716"
                    alt="Image 1"
                  />
                </div>

                <div class="flex items-center">
                  <img
                    src="https://pub-8b49af329fae499aa563997f5d4068a4.r2.dev/generations/3a18d0b6-d8d6-4dce-9bef-2fdafbd8cd14-0.png"
                    alt="Image 1"
                    class=""
                  />
                </div>
              </div>
            </div> -->

            <div class="grid grid-cols-1 gap-1 lg:grid-cols-2">
              <div class="">
                <div class="hidden lg:block">
                  <div
                    class="bg-cover bg-center pt-80 pb-80"
                    :style="'background-image: url(' + img_background + ')'"
                  >
                    <div class="flex items-center justify-center mt-10">
                      <div class="flex justify-center items-center">
                        <img
                          :src="img_data"
                          alt="Image 1"
                          class="w-96 h-96 inset-0 object-cover ease-in-out"
                        />
                      </div>
                    </div>
                    <div class="flex justify-center items-center mt-5">
                      <div class="">
                        <h3
                          :class="
                            'text-3xl font-bold xl:text-3xl ' + textColorCSS
                          "
                        >
                          {{ caseText }}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="xl:hidden sm:block">
                  <div
                    class="bg-cover h-screen bg-center flex items-center justify-center"
                    :style="'background-image: url(' + img_background + ')'"
                  >
                    <div>
                      <div class="flex justify-center items-center mt-16">
                        <img
                          :src="img_data"
                          alt="Image 1"
                          class="w-80 h-80 inset-0 object-cover ease-in-out"
                        />
                      </div>
                      <div class="flex justify-center items-center mt-5">
                        <div class="">
                          <h3
                            :class="
                              'text-3xl font-bold xl:text-3xl ' + textColorCSS
                            "
                          >
                            {{ caseText }}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="flex">
                  <div
                    v-for="obj in iphone"
                    :key="obj"
                    :class="
                      'relative inline-flex rounded-full h-10 w-10 ml-3 cursor-pointer ' +
                      obj.css
                    "
                    @click="changeColor(obj.color)"
                  ></div>
                </div> -->
              </div>
              <div class="flex items-center">
                <div class="text-white sm:ml-0 xl:ml-5 p-5 border-2 rounded-lg">
                  <h3
                    class="text-3xl font-bold xl:text-3xl text-transparent bg-clip-text bg-gradient-to-r from-green-200 via-pink-300 to-blue-500 text-shadow-white"
                  >
                    Apple Silicone Case with MagSafe for iPhone 12 Pro Max
                  </h3>

                  <h3
                    class="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-300 mt-5"
                  >
                    Product Overview Designed by Apple to complement iPhone 12
                    Pro Max, the Silicone Case with MagSafe is a delightful way
                    to protect your iPhone. The silky, soft-touch finish of the
                  </h3>

                  <div class="mt-5">
                    <h3
                      class="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-300 mt-5"
                    >
                      Text
                    </h3>
                    <div
                      class="flex justify-center py-1 sm:py-3 px-3 rounded-xl bg-gray-700"
                    >
                      <input
                        v-model="caseText"
                        placeholder="Text"
                        class="flex-grow outline-none text-sm bg-transparent text-gray-200"
                        type="String"
                      /><span
                        class="w-20 flex-none text-xs px-6 mt-1 rounded text-gray-400 cursor-pointer hover:text-yellow-200"
                        >(0/8)
                      </span>
                    </div>
                  </div>

                  <div class="mt-10">
                    <h3
                      class="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-300 mt-5"
                    >
                      Text Color
                    </h3>

                    <div class="flex mt-2">
                      <div
                        v-for="s in textColor"
                        :key="s"
                        :class="
                          'relative inline-flex rounded-full h-10 w-10 ml-3 cursor-pointer ' +
                          s.css
                        "
                        @click="changeTextColor(s.color)"
                      ></div>
                    </div>
                  </div>

                  <div class="mt-10">
                    <h3
                      class="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-300 mt-5"
                    >
                      Case Color
                    </h3>

                    <div class="flex mt-2">
                      <div
                        v-for="obj in iphone"
                        :key="obj"
                        :class="' cursor-pointer ' + obj.css"
                        @click="changeColor(obj.color)"
                      >
                        <img :src="obj.img" alt="Image 1" class="w-24" />
                      </div>
                    </div>
                  </div>

                  <div
                    @click="generateAI()"
                    class="cursor-pointer inline-block mt-10 text-xl px-2 py-1 sm:px-4 sm:py-2 border rounded text-gray-200 border-none mr-2 bg-blue-400"
                  >
                    Buy Now
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="xl:hidden sm:block">
              <div
                class="bg-cover h-screen bg-center flex items-center justify-center"
                :style="'background-image: url(' + img_background + ')'"
              >
                <div class="flex justify-center items-center">
                  <img
                    src="https://pub-8b49af329fae499aa563997f5d4068a4.r2.dev/generations/3a18d0b6-d8d6-4dce-9bef-2fdafbd8cd14-0.png"
                    alt="Image 1"
                    class="w-4/5"
                  />
                </div>
              </div>
            </div>

            <div class="hidden lg:block">
              <div
                class="bg-cover bg-center flex items-center justify-center"
                :style="'background-image: url(' + img_background + ')'"
              >
                <div class="flex justify-center items-center">
                  <img
                    src="https://pub-8b49af329fae499aa563997f5d4068a4.r2.dev/generations/3a18d0b6-d8d6-4dce-9bef-2fdafbd8cd14-0.png"
                    alt="Image 1"
                    class="w-4/5 pt-96 pb-96"
                  />
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <div class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl mt-56">
        <div class="sm:max-w-xl md:max-w-full lg:max-w-screen-2xl">
          <div
            class="px-4 sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-10 lg:px-8 lg:py-10 -mt-10"
          >
            <div class="text-center">
              <h3 class="text-3xl font-bold xl:text-4xl text-white">
                Product Features
              </h3>
            </div>
            <div class="grid grid-cols-1 gap-1 lg:grid-cols-3 mt-10">
              <div class="mt-5">
                <div class="flex justify-center">
                  <div>
                    <img
                      src="https://cdn-stamplib.casetify.com/cms/image/19e4d87c4f7e287004d09b6bf0621ec9.png"
                      alt="Image 1"
                      class="w-24 border-2 border-gray-200 rounded-full p-1"
                    />
                  </div>
                </div>

                <div class="text-center">
                  <h3
                    class="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-300 mt-5"
                  >
                    Bounce bumper corners provide maximum protection.
                  </h3>
                </div>
              </div>

              <div class="mt-5">
                <div class="flex justify-center">
                  <div>
                    <img
                      src="https://cdn-stamplib.casetify.com/cms/image/b5a6189078e15873b15550ff42225463.png"
                      alt="Image 1"
                      class="w-24 border-2 border-gray-200 rounded-full p-1"
                    />
                  </div>
                </div>

                <div class="text-center">
                  <h3
                    class="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-300 mt-5"
                  >
                    6.5 meters fall protection
                  </h3>
                </div>
              </div>
              <div class="mt-5">
                <div class="flex justify-center">
                  <div>
                    <img
                      src="https://cdn-stamplib.casetify.com/cms/image/0102a2ee1dfa20f7dd6c45fe600c639c.png"
                      alt="Image 1"
                      class="w-24 border-2 border-gray-200 rounded-full p-1"
                    />
                  </div>
                </div>

                <div class="text-center">
                  <h3
                    class="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-300 mt-5"
                  >
                    EcoShock™ protection technology
                  </h3>
                </div>
              </div>

              <div class="mt-5">
                <div class="flex justify-center">
                  <div>
                    <img
                      src="https://cdn-stamplib.casetify.com/cms/image/ecc085ca6b89a6d935c69e326cbfe15b.png"
                      alt="Image 1"
                      class="w-24 border-2 border-gray-200 rounded-full p-1"
                    />
                  </div>
                </div>

                <div class="text-center">
                  <h3
                    class="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-300 mt-5"
                  >
                    6x MIL-STD-810G
                  </h3>
                </div>
              </div>
              <div class="mt-5">
                <div class="flex justify-center">
                  <div>
                    <img
                      src="https://cdn-stamplib.casetify.com/cms/image/19c3876a5796d4f96097aca0b46dfd8c.png"
                      alt="Image 1"
                      class="w-24 border-2 border-gray-200 rounded-full p-1"
                    />
                  </div>
                </div>

                <div class="text-center">
                  <h3
                    class="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-300 mt-5"
                  >
                    Raised edges for screen protection
                  </h3>
                </div>
              </div>

              <div class="mt-5">
                <div class="flex justify-center">
                  <div>
                    <img
                      src="https://cdn-stamplib.casetify.com/cms/image/c2c8fd9b9151dc8f1db6ef465a898354.png"
                      alt="Image 1"
                      class="w-24 border-2 border-gray-200 rounded-full p-1"
                    />
                  </div>
                </div>

                <div class="text-center">
                  <h3
                    class="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-300 mt-5"
                  >
                    Wireless charging and Magsafe devices are supported.
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl mt-56">
        <div class="sm:max-w-xl md:max-w-full lg:max-w-screen-2xl">
          <div
            class="px-4 sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-10 lg:px-8 lg:py-10 -mt-10"
          >
            <div class="grid grid-cols-1 gap-1 lg:grid-cols-1">
              <div class="">
                <div class="hidden lg:block">
                  <div
                    class="bg-cover bg-center flex items-center justify-center"
                    style="
                      background-image: url('https://www.pngplay.com/wp-content/uploads/12/Crewneck-Or-Classic-T-Shirt-PNG-Clipart-Background.png');
                    "
                  >
                    <div class="flex justify-center items-center">
                      <img
                        src="https://pub-8b49af329fae499aa563997f5d4068a4.r2.dev/generations/3a18d0b6-d8d6-4dce-9bef-2fdafbd8cd14-0.png"
                        alt="Image 1"
                        class="w-4/5 pt-96 pb-96"
                      />
                    </div>
                  </div>
                </div>

                <div class="xl:hidden sm:block">
                  <div
                    class="bg-cover bg-center flex items-center justify-center"
                    style="
                      background-image: url('https://www.pngplay.com/wp-content/uploads/12/Crewneck-Or-Classic-T-Shirt-PNG-Clipart-Background.png');
                    "
                  >
                    <div class="flex justify-center items-center">
                      <img
                        src="https://pub-8b49af329fae499aa563997f5d4068a4.r2.dev/generations/3a18d0b6-d8d6-4dce-9bef-2fdafbd8cd14-0.png"
                        alt="Image 1"
                        class="w-2/5 pt-24 pb-24"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <div class="text-white sm:ml-0 xl:ml-5">
                  <h3
                    class="text-3xl font-bold xl:text-3xl text-transparent bg-clip-text bg-gradient-to-r from-green-200 via-pink-300 to-blue-500 text-shadow-white"
                  >
                    Apple Silicone Case with MagSafe for iPhone 12 Pro Max
                  </h3>

                  <h3
                    class="text-5xl font-bold xl:text-5xl text-transparent bg-clip-text bg-gradient-to-r from-green-200 via-pink-300 to-blue-500 text-shadow-white mt-10 mb-10 ml-5"
                  >
                    $20
                  </h3>

                  <h3
                    class="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-300 mt-5"
                  >
                    Product Overview Designed by Apple to complement iPhone 12
                    Pro Max, the Silicone Case with MagSafe is a delightful way
                    to protect your iPhone. The silky, soft-touch finish of the
                  </h3>

                  <div
                    @click="generateAI()"
                    class="cursor-pointer inline-block mt-10 text-xl px-2 py-1 sm:px-4 sm:py-2 border rounded text-gray-200 border-none mr-2 bg-blue-400"
                  >
                    Buy Now
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <StickFooter />
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/Footer.vue";

import { mapGetters, mapActions } from "vuex";

import Generate from "@/api/generate";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      _id: this.$route.params._id || null,
      img_select: this.$route.params.img_select,
      caseText: "",
      img_data: "",
      textColorCSS: "",
      img_background:
        "https://cdn.shopify.com/s/files/1/0568/5942/7015/products/MK043ZM_A_1.png?v=1636113716",
      iphone: [
        {
          color: "blue",
          img: "https://cdn.shopify.com/s/files/1/0568/5942/7015/products/MK043ZM_A_1.png?v=1636113716",
          css: "border-4 rounded-lg",
          status: true,
        },
        {
          color: "green",
          img: "https://cdn.shopify.com/s/files/1/0568/5942/7015/products/MK053ZM_A_1.png?v=1636113716",
          css: "",
          status: false,
        },
        {
          color: "purple",
          img: "https://cdn.shopify.com/s/files/1/0568/5942/7015/products/MK083ZM_A_1.png?v=1636113716",
          css: "",
          status: false,
        },
        {
          color: "red",
          img: "https://cdn.shopify.com/s/files/1/0568/5942/7015/products/MK073ZM_A_1.png?v=1636113716",
          css: "",
          status: false,
        },
        {
          color: "black",
          img: "https://media.discordapp.net/attachments/1080058777971212370/1125326031079882852/Iphone_Case_01.png?width=1042&height=1042",
          css: "",
          status: false,
        },
      ],
      textColor: [
        {
          color: "gray",
          css: "bg-gray-500 border-4",
          status: true,
        },
        {
          color: "red",
          css: "bg-red-500",
          status: false,
        },
        {
          color: "blue",
          css: "bg-blue-500",
          status: false,
        },
        {
          color: "green",
          css: "bg-green-500",
          status: false,
        },
      ],
    };
  },
  components: {
    ConnectWallet,
    StickFooter,
  },
  computed: mapGetters(["getUser"]),

  methods: {
    ...mapActions(["setUser"]),
    changeColor(color) {
      if (color == "blue") {
        this.img_background =
          "https://cdn.shopify.com/s/files/1/0568/5942/7015/products/MK043ZM_A_1.png?v=1636113716";

        this.iphone = [
          {
            color: "blue",
            img: "https://cdn.shopify.com/s/files/1/0568/5942/7015/products/MK043ZM_A_1.png?v=1636113716",
            css: "border-4 rounded-lg",
            status: true,
          },
          {
            color: "green",
            img: "https://cdn.shopify.com/s/files/1/0568/5942/7015/products/MK053ZM_A_1.png?v=1636113716",
            css: "",
            status: false,
          },
          {
            color: "purple",
            img: "https://cdn.shopify.com/s/files/1/0568/5942/7015/products/MK083ZM_A_1.png?v=1636113716",
            css: "",
            status: false,
          },
          {
            color: "red",
            img: "https://cdn.shopify.com/s/files/1/0568/5942/7015/products/MK073ZM_A_1.png?v=1636113716",
            css: "",
            status: false,
          },
          {
            color: "black",
            img: "https://media.discordapp.net/attachments/1080058777971212370/1125326031079882852/Iphone_Case_01.png?width=1042&height=1042",
            css: "",
            status: false,
          },
        ];
      } else if (color == "green") {
        this.img_background =
          "https://cdn.shopify.com/s/files/1/0568/5942/7015/products/MK053ZM_A_1.png?v=1636113716";
        this.iphone = [
          {
            color: "blue",
            img: "https://cdn.shopify.com/s/files/1/0568/5942/7015/products/MK043ZM_A_1.png?v=1636113716",
            css: "",
            status: false,
          },
          {
            color: "green",
            img: "https://cdn.shopify.com/s/files/1/0568/5942/7015/products/MK053ZM_A_1.png?v=1636113716",
            css: "border-4 rounded-lg",
            status: true,
          },
          {
            color: "purple",
            img: "https://cdn.shopify.com/s/files/1/0568/5942/7015/products/MK083ZM_A_1.png?v=1636113716",
            css: "",
            status: false,
          },
          {
            color: "red",
            img: "https://cdn.shopify.com/s/files/1/0568/5942/7015/products/MK073ZM_A_1.png?v=1636113716",
            css: "",
            status: false,
          },
          {
            color: "black",
            img: "https://media.discordapp.net/attachments/1080058777971212370/1125326031079882852/Iphone_Case_01.png?width=1042&height=1042",
            css: "",
            status: false,
          },
        ];
      } else if (color == "purple") {
        this.img_background =
          "https://cdn.shopify.com/s/files/1/0568/5942/7015/products/MK083ZM_A_1.png?v=1636113716";

        this.iphone = [
          {
            color: "blue",
            img: "https://cdn.shopify.com/s/files/1/0568/5942/7015/products/MK043ZM_A_1.png?v=1636113716",
            css: "",
            status: false,
          },
          {
            color: "green",
            img: "https://cdn.shopify.com/s/files/1/0568/5942/7015/products/MK053ZM_A_1.png?v=1636113716",
            css: "",
            status: false,
          },
          {
            color: "purple",
            img: "https://cdn.shopify.com/s/files/1/0568/5942/7015/products/MK083ZM_A_1.png?v=1636113716",
            css: "border-4 rounded-lg",
            status: true,
          },
          {
            color: "red",
            img: "https://cdn.shopify.com/s/files/1/0568/5942/7015/products/MK073ZM_A_1.png?v=1636113716",
            css: "",
            status: false,
          },
          {
            color: "black",
            img: "https://media.discordapp.net/attachments/1080058777971212370/1125326031079882852/Iphone_Case_01.png?width=1042&height=1042",
            css: "",
            status: false,
          },
        ];
      } else if (color == "red") {
        this.img_background =
          "https://cdn.shopify.com/s/files/1/0568/5942/7015/products/MK073ZM_A_1.png?v=1636113716";

        this.iphone = [
          {
            color: "blue",
            img: "https://cdn.shopify.com/s/files/1/0568/5942/7015/products/MK043ZM_A_1.png?v=1636113716",
            css: "",
            status: false,
          },
          {
            color: "green",
            img: "https://cdn.shopify.com/s/files/1/0568/5942/7015/products/MK053ZM_A_1.png?v=1636113716",
            css: "",
            status: false,
          },
          {
            color: "purple",
            img: "https://cdn.shopify.com/s/files/1/0568/5942/7015/products/MK083ZM_A_1.png?v=1636113716",
            css: "",
            status: false,
          },
          {
            color: "red",
            img: "https://cdn.shopify.com/s/files/1/0568/5942/7015/products/MK073ZM_A_1.png?v=1636113716",
            css: "border-4 rounded-lg",
            status: true,
          },
          {
            color: "black",
            img: "https://media.discordapp.net/attachments/1080058777971212370/1125326031079882852/Iphone_Case_01.png?width=1042&height=1042",
            css: "",
            status: false,
          },
        ];
      } else if (color == "black") {
        this.img_background =
          "https://media.discordapp.net/attachments/1080058777971212370/1125326031079882852/Iphone_Case_01.png?width=1042&height=1042";

        this.iphone = [
          {
            color: "blue",
            img: "https://cdn.shopify.com/s/files/1/0568/5942/7015/products/MK043ZM_A_1.png?v=1636113716",
            css: "",
            status: false,
          },
          {
            color: "green",
            img: "https://cdn.shopify.com/s/files/1/0568/5942/7015/products/MK053ZM_A_1.png?v=1636113716",
            css: "",
            status: false,
          },
          {
            color: "purple",
            img: "https://cdn.shopify.com/s/files/1/0568/5942/7015/products/MK083ZM_A_1.png?v=1636113716",
            css: "",
            status: false,
          },
          {
            color: "red",
            img: "https://cdn.shopify.com/s/files/1/0568/5942/7015/products/MK073ZM_A_1.png?v=1636113716",
            css: "",
            status: true,
          },
          {
            color: "black",
            img: "https://media.discordapp.net/attachments/1080058777971212370/1125326031079882852/Iphone_Case_01.png?width=1042&height=1042",
            css: "border-4 rounded-lg",
            status: false,
          },
        ];
      }
    },
    changeTextColor(color) {
      var a = this.textColor.map((s) => {
        if (s.color == color) {
          s.status = true;
          s.css = "border-4 " + `bg-${color}-500`;

          this.textColorCSS = `text-${color}-500`;
          return s;
        } else {
          s.status = false;
          s.css = `bg-${s.color}-500`;
          return s;
        }
      });

      this.textColor = a;
    },
    async getDataImage() {
      await Generate.genList({
        address: this.getUser.address,
        img: this.img_select,
        img_id: this._id,
      })
        .then((res) => {
          this.img_data = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.getDataImage();
  },
};
</script>
